<template>
   <div class="page_section" v-if="station.bySlug.id">
      <MobileTitle :title="station.bySlug.title"/>
      <Banner :content="banner"/>
      <MobileTitle :title="station.bySlug.title"/>


      <div class="content_element content_blocks_element">
         <div class="container">
            <Breadcrumb :links="breadcrumb"/>

            <div class="row content_block_item">
               <div class="col-12">
                  <div class="content_block_content">
                     <div class="html-content">
                        <h3>
                           {{ station.bySlug.street }}
                           <router-link target="_blank"
                                        :to="{name: 'city', params: {slug: station.bySlug.city.slug}}">
                              {{ station.bySlug.city.title }}
                           </router-link>
                           , {{ station.bySlug.zip }}
                        </h3>

                        <ul v-if="features.length">
                           <li v-for="(feature, index) in features" :key="index">{{feature}}</li>
                        </ul>
                        <p v-if="station.bySlug.features.db_information">
                           DB Information: {{ station.bySlug.features.db_information }}
                        </p>
                        <p v-if="station.bySlug.features.mobile_service_db">
                           mobiler Service DB: {{ station.bySlug.features.mobile_service_db }}
                        </p>
                        <p v-if="station.bySlug.env_map">
                           <a :href="cmsAsset(station.bySlug.env_map)" target="_blank" class="download-btn">
                              Umgebungsplan
                           </a>
                        </p>
                     </div>
                  </div>
                  <div class="mt25 mb25" v-if="station.bySlug.lines.length">
                     <LinesListSlider :lines="station.bySlug.lines" :title="'Lines'"/>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <Footer/>

      <a class="backend-edit" :href="backendUrl" v-if="backendAdminLoggedIn">Edit page</a>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import {gmapApi} from 'vue2-google-maps';
   import Banner from "../_content_elements/Banner";
   import MobileTitle from "../_common/MobileTitle";
   import LinesListSlider from "../_common/LinesListSlider";
   import Breadcrumb from "../_common/Breadcrumb";

   export default {
      name: "Station",
      components: {
         Breadcrumb,
         Footer,
         Banner,
         MobileTitle,
         LinesListSlider
      },
      data() {
         return {
            mapOptions: {
               zoomControl: true,
               mapTypeControl: true,
               scaleControl: true,
               streetViewControl: true,
               rotateControl: true,
               fullscreenControl: true,
               disableDefaultUi: false
            },
            infoWinOpen: 0,
         }
      },
      computed: {
         google: gmapApi,
         ...mapState([
            'station',
            'authentication'
         ]),
         banner() {
            return {
               title: this.station.bySlug.title,
               image: '/abellio_banner.jpg',
               smallBanner: false
            }
         },
         features() {
            let features = [];

            for (const [key, value] of Object.entries(this.station.bySlug.features)) {
               if (value === '1') {
                  features.push(this.__t('stations.' + key));
               }
            }

            return features;
         },
         breadcrumb() {
            return [
               {
                  title: 'Abellio Netz',
                  path: '/abellio-netz',
                  active: false
               },
               {
                  title: 'Bahnhof: ' + this.station.bySlug.title,
                  path: this.$route.path,
                  active: true
               },
            ]
         },
         backendAdminLoggedIn() {
            return this.authentication.loggedIn && this.authentication.user.user.view_admin_link;
         },
         backendUrl() {
            return this.authentication.user.user.edit_links.station + '/' + this.station.bySlug.id;
         }
      },
      created() {
         if (this.$route.params.slug) {
            this.$store.dispatch('station/getBySlug', this.$route.params.slug).then(() => {
            }, (error) => {
               if (error.response.status === 404) {
                  this.$router.replace({
                     name: '404', params: {
                        locale: this.$route.params.locale ? this.$route.params.locale : null
                     }
                  });
               }
            });
         }
      },
      beforeDestroy() {
         this.$store.commit('station/setBySlug', {});
      }
   }
</script>

<style lang="scss" scoped>
   .job_map_wrapper {
      width: 100%;
      height: 350px;
   }
</style>
