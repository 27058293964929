<template>
   <div class="content_element carousel_content_element">
      <div class="container">
         <div class="row">
            <div class="col-12 col-sm-12 col-md-3 col-lg-2">
               <nl2br tag="h2" :text="title"/>
            </div>
            <div class="col-12 col-sm-12 col-md-9 col-lg-10">
               <div class="carousel_content_wrapper">
                  <swiper
                     class="swiper"
                     ref="swiperCarousel"
                     :options="swiperOption"
                     :auto-update="true"
                     :auto-destroy="true"
                     v-if="lines && lines.length">
                     <swiper-slide v-for="(item, index) in lines" :key="index + 'carouselContent'">
                        <div class="abellio_info_box">
                           <div class="abellio_info_box_content">
                              <h4 class="abellio_info_box_title line-slider-title mb25" :style="{ color: item.color }">
                                 {{ item.title }}
                              </h4>
                              <p class="mb0">{{ item.train.title }}</p>
                              <p>{{ item.distance }} km</p>
                           </div>
                           <router-link :to="{ name: 'line', params: { slug: item.slug } }"
                                        class="abellio_info_box_more">
                              Mehr erfahren
                           </router-link>
                        </div>
                     </swiper-slide>
                     <div class="swiper-pagination" slot="pagination"></div>
                  </swiper>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import $ from "jquery";
   import {Swiper, SwiperSlide} from "vue-awesome-swiper";

   export default {
      name: "LinesListSlider",
      components: {
         Swiper,
         SwiperSlide,
      },
      props: {
         title: String,
         lines: {
            type: Array,
            required: true,
         },
      },
      data() {
         return {
            swiperOption: {
               slidesPerView: 1.5,
               spaceBetween: 16,
               breakpoints: {
                  // when window width is >= 320px
                  414: {
                     slidesPerView: 2.2,
                     spaceBetween: 16,
                  },
                  576: {
                     slidesPerView: 2.2,
                     spaceBetween: 16,
                  },
                  768: {
                     slidesPerView: 2.5,
                     spaceBetween: 16,
                  },
                  992: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
                  1280: {
                     slidesPerView: 3.5,
                     spaceBetween: 16,
                  },
               },
               pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
               },
            },
            activeContentTitle: null,
         };
      },
      computed: {
         selectedContent: function () {
            return this.lines.find((item) => {
               return item.title == this.activeContentTitle;
            });
         },
      },
      methods: {
         carouselWrapperWidth: function () {
            let element = $(this.$el).find(".carousel_content_wrapper");
            let offsetLeft = element.offset().left;
            element.outerWidth($(this.$el).outerWidth() - offsetLeft);

            this.$nextTick(() => {
               if (this.$refs.swiperCarousel) {
                  this.$refs.swiperCarousel.$swiper.update();
               }
            });
         },
         openContent(title) {
            this.activeContentTitle = title;
         },
      },
      mounted() {
         this.carouselWrapperWidth();
         window.addEventListener("resize", this.carouselWrapperWidth);
      },
   };
</script>

<style scoped>
   .swiper-slide {
      height: auto;
      padding: 5px 0 15px 0;
   }
</style>
